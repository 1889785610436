import React, { useState } from 'react'
import { Config } from './services/firebase'
import 'firebase/auth' // for authentication
import 'firebase/storage' // for storage
import 'firebase/database' // for realtime database
import 'firebase/firestore' // for cloud firestore
import FSLogo from './assets/img/PBLogo.png'
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, OAuthProvider } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { initializeApp } from 'firebase/app'
import { CookieAuthApi } from 'services/api'
import Login from 'pages/login/Login'
import ErrorPage from 'pages/error/ErrorPage'
import AuthRoute from './router/AuthRoute'
import { useCookies, withCookies } from 'react-cookie'

const app = initializeApp(Config)
const auth = getAuth(app)

const project = process.env.REACT_APP_PROJECT_ID

let domain = '.propelledbrandsdev.com'
switch (project) {
  case 'fastsigns-auth':
    domain = '.fastsigns.com'
    break
  case 'fastsigns-auth-dev':
    domain = '.fastsignsdev.com'
    break
  case 'fastsigns':
    domain = '.fastsigns.com'
    break
  case 'fastsigns-dev':
    domain = '.fastsignsdev.com'
    break
  case 'propelledbrands':
    domain = '.propelledbrands.com'
    break
  case 'nerds2go-dev':
    domain = '.nerdstogodev.com'
    break
  case 'nerds2go':
    domain = '.nerdstogo.com'
    break
  case 'suitemanagementfranchising-dev':
    domain = '.mysalonsuitedev.com'
    break
  case 'suitemanagementfranchising':
    domain = '.mysalonsuite.com'
    break
  case 'campbowwow-dev':
    domain = '.campbowwowdev.com'
    break
  case 'campbowwow':
    domain = '.campbowwow.com'
    break
  default:
    domain = '.propelledbrandsdev.com'
    break
}

const App = (props) => {
  const [user, loading, error] = useAuthState(auth)
  const uCookies = useCookies(['HGL'])
  const cookieAuthService = new CookieAuthApi()
  const setCookie = uCookies[1]
  const [hgl, setHgl] = useState(null)

  setInterval(() => {
    if (props.cookies.get('HGL') !== hgl) {
      setHgl(props.cookies.get('HGL'))
    }
  }, 5000)

  // useEffect(() => {
  //   if (window.location.hostname !== 'localhost' && !user?.impersonator && process.env.REACT_APP_ENABLE_GLOBAL_LOGIN === 'true' && !loading) {
  //     checkCookie() // use effect can't await async methods...
  //   }
  //   // eslint-disable-next-line
  // }, [hgl, user, loading])

  // async function checkCookie() {
  //   if (user) {
  //     if (!props.cookies.get('HGL')) {
  //       setTimeout(async () => {
  //         if (!props.cookies.get('HGL')) {
  //           await signOut(auth)
  //         }
  //       }, 3000)
  //     }
  //   } else {
  //     if (props.cookies.get('HGL')) {
  //       let status = await cookieAuthService.status()
  //       if (status?.success) {
  //         await signInWithCustomToken(auth, status.loginToken)
  //         setCookie('HGL', '1', { maxAge: 1209600, domain: domain })
  //       } else {
  //         setCookie('HGL', '0', { maxAge: 0, domain: domain })
  //       }
  //     }
  //   }
  // }

  async function microsoftLogin() {
    const microsoft = new OAuthProvider('microsoft.com')
    microsoft.setCustomParameters({
      tenant: process.env.REACT_APP_AZURE_TENANT_ID,
    })

    const uCreds = await signInWithPopup(auth, microsoft)
    if (uCreds) {
      const res = await cookieAuthService.login(uCreds.user)
      if (res.success) {
        setCookie('HGL', '1', { maxAge: 1209600, domain: domain })
      }
    }
  }

  async function googleLogin() {
    const google = new GoogleAuthProvider()
    const uCreds = await signInWithPopup(auth, google)
    if (uCreds) {
      const res = await cookieAuthService.login(uCreds.user)
      if (res.success) {
        setCookie('HGL', '1', { maxAge: 1209600, domain: domain })
      }
    }
  }

  async function logOut() {
    await signOut(auth)
    setCookie('HGL', '0', { maxAge: 0, domain: domain })
  }

  if (loading) {
    return (
      <div className='flex h-screen bg-black'>
        <div className='m-auto'>
          <img className='h-24 w-auto mb-5 animate-bounce' src={FSLogo} alt='FSLogo' />
        </div>
      </div>
    )
  }

  if (error) {
    return <ErrorPage />
  }

  if (!user) {
    return <Login auth={auth} googleLogin={googleLogin} microsoftLogin={microsoftLogin} />
  }

  if (user) {
    return (
      <>
        <AuthRoute user={user} auth={auth} app={app} logOut={logOut} />
      </>
    )
  }
}

export default withCookies(App)
